<template>
  <miscellaneous>
    <span class="icon-page-misc mb-2">
      <feather-icon
        icon="AlertCircleIcon"
      />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">
        Página não encontrada
      </h2>
      <p class="text-center">
        Não foi possível carregar sua requisição.
      </p>
    </div>

    <button-form
      @action="handleRedirect"
    >
      Voltar para o início
    </button-form>
  </miscellaneous>
</template>

<script>
import Miscellaneous from '@/components/Miscellaneous/Miscellaneous.vue'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'

export default {
  name: 'Error404',

  components: {
    Miscellaneous,
    ButtonForm,
  },

  computed: {
    getMessageButton() {
      if (this.$store.getters['sessions/isLoggedIn']) {
        return 'Voltar para o início'
      }

      return 'Ir para o login'
    },
  },

  methods: {
    handleRedirect() {
      this.$router.replace({
        name: 'home'
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/page-misc';
</style>
